<template>
  <div>
    <AppSidebar class="dark-mode-sidebar" />
    <div class="wrapper d-flex flex-column min-vh-100 dark-mode-main">
      <AppHeader class="dark-mode-main" />
      <div class="body flex-grow-1 px-3 dark-mode-main">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter class="dark-mode-sidebar" />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>
