import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { CBadge, CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue'
import nav from '@/_nav.js'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const firstRender = ref(true)

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon-right',
                name: item.rightIcon,
                style: {
                  marginLeft: 'auto',
                  marginRight: '10px',
                  fontSize: '0.8em', // Adjust the font size to make the icon smaller
                  width: '.2em', // Adjust the width to make the icon smaller
                  height: '.2em', // Adjust the height to make the icon smaller
                },
              }),
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
              exact: true,
            },
            {
              default: (props) =>
                h('div', { style: { position: 'relative' } }, [
                  // The main content
                  h(
                    resolveComponent(item.component),
                    {
                      active: props.isActive,
                      href: props.href,
                      onClick: () => props.navigate(),
                      class: props.isActive ? 'current-active-selected-page' : '',
                    },
                    {
                      default: () => [
                        item.icon &&
                          h(resolveComponent('CIcon'), {
                            customClassName: 'nav-icon',
                            name: item.icon,
                          }),
                        item.name,
                        item.rightIcon &&
                          h(resolveComponent('CIcon'), {
                            customClassName: 'nav-icon-right',
                            name: item.rightIcon,
                            style: {
                              marginLeft: 'auto',
                              marginRight: '10px',
                              fontSize: '1.3em',
                              width: '1em',
                              height: '1em',
                            },
                          }),
                        item.badge &&
                          h(
                            CBadge,
                            {
                              class: 'ms-auto',
                              color: item.badge.color,
                            },
                            {
                              default: () => item.badge.text,
                            },
                          ),
                      ],
                    },
                  ),
                  // Overlay for locked items
                  item.rightIcon === 'cilLockLocked' &&
                    h('div', {
                      style: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        cursor: 'not-allowed',
                        background: 'rgba(255, 255, 255, 0)', // transparent overlay
                        zIndex: 1,
                      },
                      title: 'This page is not available yet',
                      onClick: (event) => {
                        event.stopPropagation()
                        event.preventDefault()
                      },
                    }),
                ]),
            },
          )
        : h(resolveComponent(item.component), null, {
            default: () => [
              item.icon &&
                h(resolveComponent('CIcon'), {
                  customClassName: 'nav-icon',
                  name: item.icon,
                }),
              item.name,
              item.rightIcon &&
                h(resolveComponent('CIcon'), {
                  customClassName: 'nav-icon-right',
                  name: item.rightIcon,
                  style: {
                    marginLeft: 'auto',
                    marginRight: '10px',
                  },
                }),
            ],
          })
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
